<template>
  <div>
      <AddDirectionForm></AddDirectionForm>
  </div>
</template>

<script>
import AddDirectionForm from '../../../components/Dashboard/Directions/AddDirectionForm'
export default {
  components: { AddDirectionForm },

}
</script>